<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
import Constants from "@/data/Constants";
import Role from "@/data/Roles";

let roleChecker = new Role();
let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  data() {
    return {
      role_admin: Constants.ROLE_OWNER,
      title: "Departments",
      access_property: "Department",
      role: preference.getData(Preference.KEY_ROLE_ID),
      myName: preference.getData(Preference.KEY_NAME),
      myEmail: preference.getData(Preference.KEY_EMAIL),
      myOrgRole: "5",
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Departments",
          active: true,
        },
      ],
      organizationFields: [
        { key: "name", sortable: true, label: "Name" },
        { key: "address", sortable: false, label: "Details" },
      ],
      orgMemberFields: [
        { key: "name", sortable: true, label: "Name" },
        { key: "email", sortable: true, label: "Email" },
        { key: "role_id", sortable: false, label: "Role" },
      ],
      currentRoleList: [],
      orgMembersList: [],
      organizationTableData: [],
      orgMemberTableData: [],
      roleIDList: [],
      memberNameList: [],
      memberEmailList: [],
      projectTypeList: [],
      organizationList: [],
      totalRowsOrg: 1,
      totalRowsMembers: 1,
      orgCurrentPage: 1,
      orgPerPage: 10,
      orgPageOptions: [10, 25, 50],
      memberPerPage: 10,
      memberPageOptions: [10, 25, 50],
      memberCurrentPage: 1,
      filterOrg: null,
      filterOrgMembers: null,
      filterOnOrg: [],
      filterOnMembers: [],
      sortBy: "orderid",
      sortDesc: false,
      selected: {},
      editedItem: "",
      selectedOrgName: "",
      selectedOrganizationID: 1,
      roleIDMap: {},
      roleMap: {},
      displayValueMap: {},
      memberUserEmail: "",
      memberUserName: "",
      memberUserRole: "Member",
      organizationID: "",
      roleObjectList: {},
      roleArray: [],
      orgArray: [],
      organizationObjectList: {},
      addProjectOrgID: "",
      organizationMap: {},
      org_name: "",
      user_id: "",
      org_address: "",
      org_domain: "",
      loadingOrganizations: false,
      loadingMembers: false,
      roleEditItem: "",
    };
  },
  computed: {
    orgRows() {
      return this.organizationList.length;
    },
    memberRows() {
      return this.orgMembersList.length;
    },
  },
  mounted() {
    this.addColumn(this.role);
    this.totalRowsOrg = this.organizationList.length;
    this.totalRowsMembers = this.orgMembersList.length;
  },
  components: {
    Layout,
    PageHeader,
  },
  name: "projects",

  watch: {
    filterOrgMembers() {
      this.memberCurrentPage = 1;
    },
  },

  methods: {
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id, resource, action);
    },

    doubleRoleCheck(myRole,myRoleOrg,access_property,action){
      // console.log('xxx',myRole,myRoleOrg,access_property,action);
      if(this.isRoleAccess(myRole,access_property,action) || this.isRoleAccess(myRoleOrg,access_property,action)){
        return true

       }
       return false
      
    },
    addColumn(myRole) {
      if (this.isRoleAccess(myRole, this.access_property, "read")) {
        this.organizationFields.push({ key: "action", sortable: false, label: "Action" });
      }
      if (this.isRoleAccess(myRole, "Member", "read")) {
        this.orgMemberFields.push({ key: "action", sortable: false, label: "Action" });
      }
    },
    createOrganization() {
      let _this = this;
      let accessPermission = this.doubleRoleCheck(this.role,this.myOrgRole,this.access_property,'write')
      _this.loadingOrganizations = true;
      let swal = this.$swal;
      httpRequest
        .createOrganization(_this.org_name, "0", _this.user_id, _this.org_address, accessPermission)
        .then(function (response) {
          _this.loadingOrganizations = false;
          if (response.success) {
            _this.$bvModal.hide("modal-add-org");
            _this.getOrganizationList();
          } else {
            swal(response.message);
          }
        });
    },
    editOrganization() {
      let _this = this;
      _this.loadingOrganizations = true;
      let accessPermission = this.doubleRoleCheck(this.role,this.myOrgRole,this.access_property,'update')
      let swal = this.$swal;
      httpRequest
        .editOrganization(
          _this.editedItem.name,
          "0",
          _this.user_id,
          _this.editedItem.address,
          _this.editedItem.organization_id,
          accessPermission
        )
        .then(function (response) {
          _this.loadingOrganizations = false;
          if (response.success) {
            _this.$bvModal.hide("modal-edit-org");
            _this.getOrganizationList();
          } else {
            swal(response.message);
          }
        });
    },
    getOrganizationList() {
      let _this = this;
      let accessPermission = this.doubleRoleCheck(this.role,this.myOrgRole,this.access_property,'read')
      _this.loadingOrganizations = true;
      return httpRequest.getOrganizationList(accessPermission).then(function (response) {
        _this.loadingOrganizations = false;
        if (response.success) {
          _this.organizationList = response.data;
          _this.organizationTableData.length = 0;
          let tempObj = {};
          _this.organizationList.forEach(function (orgData, index) {
            if(!accessPermission){
              tempObj = {
              serial_number: index + 1,
              name: orgData.organization.name,
              address: orgData.organization.address,
              organization_id: orgData.organization.organization_id,
            };
            }else{
             tempObj = {
              serial_number: index + 1,
              name: orgData.name,
              address: orgData.address,
              organization_id: orgData.organization_id,
            };
            }
        
            _this.organizationTableData.push(tempObj);

            if (_this.organizationTableData.length > 0) {
              _this.selected = _this.organizationTableData[0];
              _this.selectedOrgName = _this.organizationTableData[0].name;
              _this.selectedOrganizationID =
                _this.organizationTableData[0].organization_id;
              _this.getOrgMembersList(_this.selectedOrganizationID);
            }
          });
        }
        return;
      });
    },
    addOrgMember() {
      let _this = this;
      _this.loadingMembers = true;
      let accessPermission = this.doubleRoleCheck(this.role,this.myOrgRole,'Member','write')
      let swal = this.$swal;
      let userRoleID = this.roleIDMap.get(_this.memberUserRole);
      return httpRequest
        .addMemberToOrganization(
          _this.selectedOrgID,
          _this.memberUserEmail,
          _this.memberUserName,
          userRoleID,
          accessPermission
        )
        .then(function (response) {
          _this.loadingMembers = false;
          if (response.success) {
            _this.getOrgMembersList(_this.selectedOrgID);
            _this.$bvModal.hide("modal-1");
            swal("Member adding mail has been sent.");
            _this.memberUserName = "";
            _this.memberUserEmail = "";
          } else {
            swal(response.message);
          }
          return;
        });
    },
    getRoleList(role_type) {
      let _this = this;
      httpRequest.getRole(role_type).then(function (response) {
        if (response.success) {
          _this.currentRoleList = response.data;

          // For avoiding data collision
          Vue.set(_this.roleObjectList, 5, "Member");
          Vue.set(_this.roleObjectList, 4, "Owner");
        }
      });
    },
    getOrgMembersList(org_id) {
      let _this = this;
      _this.loadingMembers = true;
      httpRequest.getOrganizationMembers(org_id).then(function (response) {
        _this.loadingMembers = false;
        _this.selectedOrgID = org_id;
        if (response.success) {
          _this.orgMembersList = response.data;
          _this.orgMemberTableData.length = 0;

          let tempObj = {};
          _this.orgMembersList.forEach(function (orgMemberData, index) {
            if (
              orgMemberData.user.name == _this.myName &&
              orgMemberData.user.email == _this.myEmail
            ) {
              _this.myOrgRole = orgMemberData.role_id;
              _this.addColumn(_this.myOrgRole.toString());
            }
            tempObj = {
              serial_number: index + 1,
              name: orgMemberData.user.name,
              email: orgMemberData.user.email,
              user_id: orgMemberData.user.user_id,
              user_role_id: orgMemberData.user.role_id,
              role_id: _this.displayValueMap.get(orgMemberData.role_id),
            };
            _this.orgMemberTableData.push(tempObj);
          });
        } else {
          _this.orgMembersList = [];
        }
      });
    },
    removeOrganizationMember(userData) {
      let _this = this;
      let accessPermission = this.doubleRoleCheck(this.role,this.myOrgRole,'Member','delete')
      _this
        .$swal({
          text: "Are you sure you want to remove this member?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "CANCEL",
        })
        .then((result) => {
          if (result.value) {
            httpRequest
              .deleteMemberFromOrganization(this.selectedOrganizationID, userData.email, accessPermission)
              .then(function (response) {
                if (response.success) {
                  _this.getOrgMembersList(_this.selectedOrganizationID);
                } else {
                  _this.$swal({
                    text: "You can't remove this member!",
                  });
                }
              });
          }
        });
    },
    editOrganizationMember() {
      let _this = this;
      // Display a modal with current state,
      let accessPermission = this.doubleRoleCheck(this.role,this.myOrgRole,'Member','update')
      _this.loadingMembers = true;
      let swal = this.$swal;
      let userRoleID = this.roleIDMap.get(_this.roleEditItem.role_id);
      return httpRequest
        .updateOrganizationMember(
          _this.selectedOrgID,
          _this.roleEditItem.email,
          _this.roleEditItem.name,
          userRoleID,
          accessPermission
        )
        .then(function (response) {
          _this.loadingMembers = false;
          if (response.success) {
            _this.getOrgMembersList(_this.selectedOrgID);
            _this.$bvModal.hide("modal-edit-role");
            swal(response.message);
          } else {
            swal(response.message);
          }
          return;
        });
    },
    onFilteredOrg(filteredItems) {
      this.totalRowsOrg = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredOrgMembers(filteredItems) {
      this.totalRowsMembers = filteredItems.length;
      this.currentPage = 1;
    },
    myRowClickHandler(record) {
      this.selected = record;
      this.selectedOrgName = record.name;
      this.selectedOrganizationID = record.organization_id;
      this.getOrgMembersList(record.organization_id);
    },
    myEditedItem(row) {
      this.editedItem = row;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item == this.selected) {
        return "table-active";
      }
    },
    myRoleEditItem(item) {
      this.roleEditItem = item;
    },
    deleteDepartment(projectData) {
      let _this = this;
      _this
        .$swal({
          text: "Are you sure you want to delete this department?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "CANCEL",
        })
        .then((result) => {
          let accessPermission = this.doubleRoleCheck(this.role,this.myOrgRole,this.access_property,'delete')
          if (result.value) {
            httpRequest
              .deleteDepartment(projectData.organization_id, accessPermission)
              .then(function (response) {
                if (response.success) {
                  _this.getOrganizationList();
                  _this.$swal({
                    text: "Delete Successful",
                  });
                } else {
                  _this.$swal({
                    text: "You can't delete this department!",
                  });
                }
              });
          }
        });
    },
  },
  async created() {
    this.roleIDMap = new Map();
    this.roleIDMap.set("User", 1);
    this.roleIDMap.set("Admin", 2);
    this.roleIDMap.set("Super Admin", 3);
    this.roleIDMap.set("Owner", 4);
    this.roleIDMap.set("Member", 5);
    this.roleIDMap.set("Product Owner", 6);
    this.roleIDMap.set("Project Manager", 7);
    this.roleIDMap.set("Project Member", 8);
    this.roleIDMap.set("Developer", 9);
    this.roleIDMap.set("QA", 10);
    this.roleIDMap.set("Designer", 11);

    this.roleMap = new Map();
    this.roleMap.set(1, "User");
    this.roleMap.set(2, "Admin");
    this.roleMap.set(3, "Super Admin");
    this.roleMap.set(4, "Owner");
    this.roleMap.set(5, "Member");
    this.roleMap.set(6, "Product Owner");
    this.roleMap.set(7, "Project Manager");
    this.roleMap.set(8, "Project Member");
    this.roleMap.set(9, "Developer");
    this.roleMap.set(10, "QA");
    this.roleMap.set(11, "Designer");

    this.displayValueMap = new Map();
    this.displayValueMap.set(1, "User");
    this.displayValueMap.set(2, "Admin");
    this.displayValueMap.set(3, "Super Admin");
    this.displayValueMap.set(4, "Owner");
    this.displayValueMap.set(5, "Member");
    this.displayValueMap.set(6, "Product Owner");
    this.displayValueMap.set(7, "Project Manager");
    this.displayValueMap.set(8, "Project Member");
    this.displayValueMap.set(9, "Developer");
    this.displayValueMap.set(10, "QA");
    this.displayValueMap.set(11, "Designer");

    this.organizationMap = new Map();
    await this.getRoleList("org");
    await this.getOrganizationList();

    this.user_id = preference.getProfileData().userId;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!--Organization List block-->
      <div class="col-xl-4 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">Department List</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="orgPerPage"
                      size="sm"
                      :options="orgPageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterOrg"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!--Organization List table-->
            <b-table
              show-empty
              class="mb-0"
              ref="organizationTable"
              :items="organizationTableData"
              :fields="organizationFields"
              :striped="false"
              responsive=""
              :per-page="orgPerPage"
              :current-page="orgCurrentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filterOrg"
              :filter-included-fields="filterOnOrg"
              :bordered="true"
              :hover="false"
              :dark="false"
              :tbody-tr-class="rowClass"
              @filtered="onFilteredOrg"
              @row-clicked="myRowClickHandler"
              :busy="loadingOrganizations"
            >
              <template
                v-for="field in organizationFields"
                v-slot:[`head(${field.key})`]="{ label }"
              >
                <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
                  label
                }}</span>
              </template>

              <template slot="actions" slot-scope="projectList">
                <b-btn size="sm" @click="myRowClickHandler(projectList.item)"
                  >Details</b-btn
                >
              </template>

              <template v-slot:cell(domain)="row">
                <b-link :href="row.item.domain">{{ row.item.domain }}</b-link>
              </template>

              <template
                v-slot:cell(action)="row"
                v-if="doubleRoleCheck(role,myOrgRole,access_property,'read')"
              >
                <div style="display: flex; gap: 8px">
                  <b-button
                    v-b-modal.modal-edit-org
                    variant="outline-info"
                    size="sm"
                    v-if="doubleRoleCheck(role,myOrgRole,access_property,'update')"
                    @click="myEditedItem(row.item)">
                    <i class="ri-pencil-fill"></i>
                  </b-button>
                  
                  <b-button
                    v-if="doubleRoleCheck(role,myOrgRole,access_property,'delete')"
                    variant="outline-danger"
                    size="sm"
                    v-on:click="deleteDepartment(row.item)">
                    <i class="ri-delete-bin-line"></i>
                  </b-button>
                </div>
              </template>

              <!-- <template
                v-slot:cell(action)="row"
                v-else-if="this.role != 3 && this.myOrgRole == 4"
              >
                <div style="display: flex; gap: 8px">
                  <b-button
                    v-b-modal.modal-edit-org
                    variant="outline-info"
                    size="sm"
                    @click="myEditedItem(row.item)"
                  >
                    <i class="ri-pencil-fill"></i>
                  </b-button>
                </div>
              </template> -->

              <template #table-busy>
                <div class="text-center">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>

            <!--Organization List modals-->
            <div class="align-items-center text-center text-md-left">
              <b-modal id="modal-add-org" centered title="Add Department" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-building-2-line auti-custom-input-icon"></i>
                    <label for="orgname">Department Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="orgname"
                      placeholder="Enter name"
                      v-model="org_name"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="mdi mdi-home-search-outline auti-custom-input-icon"></i>
                    <label for="orgadress">Details</label>
                    <input
                      type="text"
                      class="form-control"
                      id="orgadress"
                      placeholder="Enter details"
                      v-model="org_address"
                    />
                  </div>
                  <div class="mt-4 text-center">
                    <b-button
                      class="customs-bg-color"
                      variant="primary"
                      size="lg"
                      type="submit"
                      v-on:click="createOrganization()"
                    >
                      SUBMIT
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
              <b-modal id="modal-edit-org" centered title="Edit Department" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-building-2-line auti-custom-input-icon"></i>
                    <label for="org_name">Department Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="org_name"
                      placeholder="Enter name"
                      v-model="editedItem.name"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="mdi mdi-home-search-outline auti-custom-input-icon"></i>
                    <label for="org_address">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="org_address"
                      placeholder="Enter address"
                      v-model="editedItem.address"
                    />
                  </div>
                  <div class="mt-4 text-center">
                    <b-button
                      class="customs-bg-color"
                      variant="primary"
                      size="lg"
                      type="submit"
                      v-on:click="editOrganization"
                      >UPDATE
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>

          <div class="card-footer bg-white">
            <div class="row">
              <div class="col-md-auto mb-2 mb-md-0 text-center text-md-left">
                <b-button
                  v-b-modal.modal-add-org
                  variant="primary"
                  class="customs-bg-color"
                  v-if="doubleRoleCheck(this.role,myOrgRole,access_property,'write')"
                  ><i class="ri-add-line" style="vertical-align: bottom"></i> ADD DEPARTMENT
                </b-button>
              </div>
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <!-- pagination -->
                  <b-pagination
                    v-model="orgCurrentPage"
                    :limit="3"
                    :total-rows="orgRows"
                    :per-page="orgPerPage"
                    class="pagination-rounded mb-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Organization member block-->
      <div class="col-xl-8 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">Members of {{ selectedOrgName }}</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="members-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="memberPerPage"
                      size="sm"
                      :options="memberPageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="members-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterOrgMembers"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!--Organization member table-->
            <b-table
              show-empty
              class="mb-0"
              :striped="false"
              :bordered="true"
              :hover="false"
              :dark="false"
              :fields="orgMemberFields"
              :items="orgMemberTableData"
              :busy="loadingMembers"
              responsive=""
              :per-page="memberPerPage"
              :current-page="memberCurrentPage"
              :filter="filterOrgMembers"
              :filter-included-fields="filterOnMembers"
              @filtered="onFilteredOrgMembers"
            >
              <template
                v-for="field in orgMemberFields"
                v-slot:[`head(${field.key})`]="{ label }"
              >
                <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
                  label
                }}</span>
              </template>
              <template v-slot:cell(role_id)="row">
                <b-badge pill variant="secondary" v-if="row.item.role_id == 'Member'">
                  {{ row.item.role_id }}
                </b-badge>
                <b-badge pill variant="primary" v-else>
                  {{ row.item.role_id }}
                </b-badge>
              </template>

              <template
                v-slot:cell(action)="row"
                v-if="doubleRoleCheck(role,myOrgRole,'Member','read')"
              >
                <div style="display: flex; gap: 8px">
                  <b-button
                    v-b-modal.modal-edit-role
                    v-if="doubleRoleCheck(role,myOrgRole,'Member','update')"
                    variant="outline-info"
                    size="sm"
                    v-on:click="myRoleEditItem(row.item)"
                  >
                    <i class="ri-pencil-fill"></i>
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    v-if="doubleRoleCheck(role,myOrgRole,'Member','delete')"
                    v-on:click="removeOrganizationMember(row.item)"
                  >
                    <i class="ri-delete-bin-line"></i>
                  </b-button>
                </div>
              </template>

              <template #table-busy>
                <div class="text-center">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>

            <!--Organization member modal-->
            <div class="align-items-center text-center text-md-left">
              <b-modal id="modal-1" centered title="Add member" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-user-line auti-custom-input-icon"></i>
                    <label for="username">Name</label>
                    <input
                      v-model="memberUserName"
                      type="text"
                      class="form-control"
                      id="username"
                      placeholder="Enter name"
                    />
                  </div>

                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-mail-line auti-custom-input-icon"></i>
                    <label for="useremail">Email</label>
                    <input
                      v-model="memberUserEmail"
                      type="email"
                      class="form-control"
                      id="useremail"
                      placeholder="Enter email"
                    />
                  </div>
                  <div
                    v-if="this.role == 3"
                    class="form-group auth-form-group-custom mb-4"
                  >
                    <i class="ri-medal-line auti-custom-input-icon"></i>
                    <label for="role">Role</label>
                    <select
                      class="form-control custom-select"
                      id="role"
                      v-model="memberUserRole"
                    >
                      <option v-for="(value, key) in roleObjectList" v-bind:key="key">
                        {{ value }}
                      </option>
                    </select>
                  </div>

                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      class="customs-bg-color"
                      size="lg"
                      type="submit"
                      v-on:click="addOrgMember"
                      >SUBMIT
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
              <b-modal id="modal-edit-role" centered title="Edit member" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-user-line auti-custom-input-icon"></i>
                    <label for="username">Name</label>
                    <input
                      v-model="roleEditItem.name"
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Enter name"
                    />
                  </div>

                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-mail-line auti-custom-input-icon"></i>
                    <label for="useremail">Email</label>
                    <input
                      v-model="roleEditItem.email"
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Enter email"
                    />
                  </div>
                  <div>
                    <div
                     
                      class="form-group auth-form-group-custom mb-4"
                    >
                      <i class="ri-medal-line auti-custom-input-icon"></i>
                      <label for="role">Role</label>
                      <select
                        class="form-control custom-select"
                        id="role_id"
                        v-model="roleEditItem.role_id"
                      >
                        <option v-for="(value, key) in roleObjectList" v-bind:key="key">
                          {{ value }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- <div v-else-if="this.role == 1">
                    <div
                      v-if="roleEditItem.role_id != 'Member'"
                      class="form-group auth-form-group-custom mb-4"
                    >
                      <i class="ri-medal-line auti-custom-input-icon"></i>
                      <label for="role">Role</label>
                      <select
                        class="form-control custom-select"
                        id="role_id"
                        v-model="roleEditItem.role_id"
                      >
                        <option v-for="(value, key) in roleObjectList" v-bind:key="key">
                          {{ value }}
                        </option>
                      </select>
                    </div>
                  </div> -->

                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      class="customs-bg-color"
                      size="lg"
                      type="submit"
                      v-on:click="editOrganizationMember"
                      >SUBMIT
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>

          <div class="card-footer bg-white">
            <!--Organization member btn & pagination-->
            <div class="row">
              <div class="col-md-auto mb-2 mb-md-0 text-center text-md-left">
                <b-button
                  v-b-modal.modal-1
                  variant="primary"
                  v-if="doubleRoleCheck(this.role,myOrgRole,'Member','write')"
                  class="customs-bg-color"
                  ><i class="ri-add-line" style="vertical-align: bottom"></i> ADD MEMBER
                </b-button>
              </div>
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <!-- pagination -->
                  <b-pagination
                    v-model="memberCurrentPage"
                    :limit="3"
                    :total-rows="memberRows"
                    :per-page="memberPerPage"
                    class="pagination-rounded mb-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}
</style>
